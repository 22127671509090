import React, { useCallback, useContext, useEffect, useState } from "react";
import debounce from 'lodash/debounce'; // Correct import for debounce
import { customShippingAddressElemObj } from "constants";

import { getLocationDataBasedOnZipCode, getCustomerCountry, getCustomerStateByCountryId } from "service/FormService";

import Dropdown from "components/form/Dropdown";
import { FormContext } from "contexts/FormContext";

const CustomShippingAddressForm = ({ customShippingFormData, setcustomShippingFormData, errors, setErrors, setcustomShippingData, handleChange }) => {
    const [shippingCountries, setShippingCountries] = useState([]);
    const [shippingState, setShippingState] = useState([]);
    const { setcustomShippingFormref } = useContext(FormContext);

    const debouncedApiCallToFetchCountryByZipCode = useCallback(debounce((value) => {
        if (value) {
            getLocationDataBasedOnZipCode(value).then(async (response) => {
                if (response?.success && response?.data) {
                    const { cityName, countryId, stateId } = response.data;

                    setcustomShippingFormData(prevData => ({ ...prevData, ShippingCity: cityName }));
                    // Clear the error for the field

                    if (shippingState.length === 0 && parseInt(countryId)) {
                        const stateRes = await getCustomerStateFunc(countryId);
                        const currentState = stateRes.find((obj) => obj?.id === stateId);

                        if (currentState) {
                            const dropdownObj = { label: currentState.name, value: currentState.id };
                            setcustomShippingFormData(prevData => ({ ...prevData, ShippingState: dropdownObj }));
                        }
                    }
                    setcustomShippingData(response.data);
                } else {
                    setcustomShippingFormData(prevData => ({ ...prevData, ShippingState: "", ShippingCity: "", ShippingCountry: "" }));
                }

                setErrors(prevErrors => ({
                    ...prevErrors,
                    ["ShippingCity"]: "",
                    ["ShippingState"]: "",
                    ["ShippingCountry"]: ""
                }));

            }).catch(() => { });
        } else {
            setcustomShippingFormData(prevData => ({ ...prevData, ShippingState: "", ShippingCity: "", ShippingCountry: "" }));
        }
    }, 500), []);

    const getCustomerStateFunc = async (countryId) => {
        const allState = await getCustomerStateByCountryId(countryId);
        const dropDownVal = allState.data.map((obj) => ({ label: obj.name, value: obj.id }));
        dropDownVal.unshift({
            "label": "Search State",
            "value": 0
        })

        if (dropDownVal && dropDownVal.length) {
            setShippingState(dropDownVal);
            setcustomShippingFormData(prevData => ({ ...prevData, ShippingState: dropDownVal[0] }));
        }
        return allState?.data;
    };

    useEffect(() => {
        (async () => {
            const allCountry = await getCustomerCountry();
            if (allCountry.data) {
                const dropDownVal = allCountry.data.map((obj) => ({ label: obj.name, value: obj.id }));
                setShippingCountries(dropDownVal);
                setcustomShippingFormData(prevData => ({ ...prevData, ShippingCountry: dropDownVal[0] }));
                getCustomerStateFunc(dropDownVal[0].value);
            }
        })();
        return () => {
            debouncedApiCallToFetchCountryByZipCode.cancel();
        };
    }, []);

    useEffect(() => {
        if (customShippingFormData?.ShippingZip) {
            debouncedApiCallToFetchCountryByZipCode(customShippingFormData?.ShippingZip)
        } else {
            setcustomShippingFormData(prevData => ({ ...prevData, ShippingState: "", ShippingCity: "", ShippingCountry: "" }));
        }

    }, [customShippingFormData?.ShippingZip])

    useEffect(() => {
        setcustomShippingFormref(customShippingFormData);
    }, [customShippingFormData]);

    return (
        <div className="w-full bg-white shadow-lg rounded-md border border-neutral-200 mb-6 p-6 z-10">
            <div className="block uppercase tracking-wide text-gray-500 text-lg font-bold mb-6">
                Shipping Information
            </div>
            <div className="grid grid-cols-12 gap-6">
                {customShippingAddressElemObj.map(({ name, label, ...props }) => (
                    <div className="col-span-12 lg:col-span-6" key={name}>
                        <label className="w-full block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2">
                            {label}
                            <span className="text-rose-500 text-xl leading-none">{name != 'ShippingSuite' ? "*" : ""}</span>
                        </label>
                        <input
                            name={name}
                            value={customShippingFormData[name]}
                            onChange={(e) => handleChange(name, e.target.value)}
                            {...props}
                            className={`form-input ${errors[name] ? "border-red11" : ''}`}
                        />
                        {errors[name] ? <div className='text-red-500 text-small-text mt-1 '>{errors[name]}</div> : <div className='text-red-500 text-small-text mt-1'>&nbsp;</div>}
                    </div>
                ))}
                <div className="col-span-12 lg:col-span-6">
                    <label className="w-full block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2">
                        Country
                        <span className="text-rose-500 text-xl leading-none">*</span>
                    </label>

                    <Dropdown
                        options={shippingCountries}
                        defaultValue={customShippingFormData?.ShippingCountry || (shippingCountries.length > 0 && shippingCountries[0])}
                        isSearchable={true}
                        onChange={(data) => {
                            setShippingState([]);
                            handleChange("ShippingCountry", data);
                            getCustomerStateFunc(data.value);
                            setcustomShippingFormData(prevData => ({ ...prevData, ShippingState: "" }));
                        }}
                        isMulti={false}
                    />
                    <div className='text-red-500 text-small-text mt-1 '>{errors["ShippingCountry"] ?? ''}</div>
                </div>
                <div className="col-span-12 lg:col-span-6">
                    <label className="w-full block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2">
                        State / Province
                        <span className="text-rose-500 text-xl leading-none">*</span>
                    </label>
                    <Dropdown
                        options={shippingState}
                        isSearchable={true}
                        defaultValue={customShippingFormData?.ShippingState || (shippingState.length > 0 && shippingState[0])}
                        onChange={(data) => handleChange("ShippingState", data)}
                        isMulti={false}
                        optionOnSearch={true}
                    />
                    <span className="text-xs">You need to start typing state name of your choice to get suggetion.</span>
                    <div className='text-red-500 text-small-text mt-1 '>{errors["ShippingState"] ?? ''}</div>
                </div>
            </div>
        </div>
    );
};

const CustomShippingAddress = ({ customShippingFormData, setcustomShippingFormData, errors, setErrors, handleChange }) => {
    return (
        <CustomShippingAddressForm
            customShippingFormData={customShippingFormData}
            setcustomShippingFormData={setcustomShippingFormData}
            errors={errors}
            setErrors={setErrors}
            handleChange={handleChange}
            setcustomShippingData={(data) => setcustomShippingFormData(prev => ({ ...prev, ...data }))}
        />
    );
};

export default CustomShippingAddress;

