import { createContext } from "react";

export const FormContext = createContext({
  storeId: null,
  formId: null,
  isUseTemplate: false,
  setCartItems: null,
  cartItems: [],
  formData: [{}],
});
