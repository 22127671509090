import React, { useContext, useState } from "react";
import { toast } from "react-toastify";

import Modal from "./Modal";
import { FormContext } from "../contexts/FormContext";

import { decryptPassword } from "../service/FormService";

export default function PasswordProtectedModal({ logoImageUrl }) {
  const { formData, setmodalState } = useContext(FormContext);

  const [pagePassword, setPagePassword] = useState("");

  const handleVerifyPassword = async () => {
    if (formData?.protectedPassword) {
      const formDataResponse = await decryptPassword(
        formData.protectedPassword
      );

      if (formDataResponse?.data == pagePassword) {
        setmodalState((prev) => ({ ...prev, isVisible: false }));
      } else {
        toast("invalid crediantials");
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleVerifyPassword(e); // Call handleVerifyPassword on Enter key press
    }
  };

  return (
    <>
      <div
        id="landingpopup"
        className="overflow-y-auto overflow-x-hidden fixed z-50 justify-center items-center h-modal inset-0 text-default-text"
      >
        <div className="w-full h-full bg-[#000000] bg-opacity-[0.50] flex items-center justify-center">
          <div className="relative px-[16px] w-full max-w-[640px] h-auto">
            <div className="relative bg-[#ffffff] max-h-screen overflow-y-auto h-full rounded-[10px]">
              <div className="text-center mt-[30px] md:mt-[50px] px-[15px]">
                <div className="text-center mt-[30px] md:mt-[50px] px-[15px]">
                  <div className="mb-[10px]">
                    <img
                      itemProp="image"
                      src={logoImageUrl}
                      alt=""
                      sizes=""
                      title=""
                      className="inline-block"
                      loading="lazy"
                    />
                  </div>
                </div>
                <div className="text-medium-text font-bold mb-[20px]">
                  <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <div className="flex flex-wrap items-center justify-center gap-2 max-w-xs mx-auto">
                      <div>
                        <h2 className="text-center text-gray-900 mb-[10px]">
                          {formData?.passwordPopUpText?.title &&
                          formData?.passwordPopUpText?.description ? (
                            <div>
                              <div className="mb-[10px]">
                                {formData?.passwordPopUpText?.title}
                              </div>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html:
                                    formData?.passwordPopUpText?.description,
                                }}
                              />
                            </div>
                          ) : (
                            "This store is password protected. Please enter password."
                          )}
                        </h2>
                      </div>

                      <div className="w-full text-center">
                        <div className="w-full flex justify-center">
                          <input
                            type="password"
                            className="block w-full bg-white border border-neutral-200 hover:border-neutral-300 focus:border-neutral-300 focus:ring-0 focus:shadow-lg px-2 py-2 mb-4 rounded-md"
                            placeholder="**********"
                            value={pagePassword}
                            onChange={(e) => setPagePassword(e.target.value)}
                            onKeyDown={handleKeyDown}
                          />
                        </div>
                      </div>
                      <div>
                        <button
                          onClick={handleVerifyPassword}
                          className="btn btn-lg btn-secondary hover:scale-[1.02] transition-all rounded-full text-center w-full md:w-auto mt-[10px]"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="h-[100px] bg-primary w-full"></div>
            </div>
          </div>
        </div>
      </div>
      {/* <Modal> */}
      {/* <div className="w-full flex flex-col justify-center items-center">
          <p className="text-2xl my-4 text-left w-full">
            Enter The Password To Access This Page.
          </p>
          <input
            type="password"
            className="block w-full bg-white border border-neutral-200 hover:border-neutral-300 focus:border-neutral-300 focus:ring-0 focus:shadow-lg px-2 py-2 mb-4 rounded-md"
            placeholder="Enter Access Password"
            value={pagePassword}
            onChange={(e) => setPagePassword(e.target.value)}
          />

          <button
            className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
            onClick={handleVerifyPassword}
          >
            Verify Password and Access Page
          </button>
        </div> */}
      {/* </Modal> */}
    </>
  );
}
