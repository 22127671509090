import ShippingAddress from "../../../ShippingAddress";

const ShippingSection = ({ addressList, setSelectedShippingAddress }) => {
  return (
    <div className="relative w-full">
      <div className="mx-auto">
        <div className="flex justify-between items-center py-[10px] md:py-[20px] overflow-hidden rounded-[8px] bg-primary mb-[26px]">
          <div className="md:pl-[30px] pl-[15px]">
            <div className="flex flex-wrap gap-x-[20px] items-center">
              <div className="text-primarytext text-normal-text font-bold">
                Please Choose Shipping Address
                <span className="text-rose-500 text-2xl leading-none">*</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ul className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-[15px] my-[36px]">
        {addressList.map((address, index) => (
          <li className="text-center" key={index}>
            <ShippingAddress
              address={address}
              setSelectedShippingAddress={setSelectedShippingAddress}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ShippingSection;
