import React from "react";

const Image = ({ label, formLabelFontSize, className, browse, icon }) => {
  return (
    <>
      <div className={`w-full px-[15px] mb-[15px] ${className}`}>
        <div className="rounded bg-white w-full">
          {label && (
            <label
              className={`flex font-bold mb-[5px] ${
                formLabelFontSize ? formLabelFontSize : "text-normal-text"
              }`}
            >
              {label}
              {icon && <span className="material-icons mr-2 ">{icon}</span>}
            </label>
          )}
          {browse && (
            <div className="max-w-xs min-h-[80px]">
              <img src={browse} className="min-h-[80px] max-w-xs" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Image;
