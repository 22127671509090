import React, { useEffect, useState } from "react";

export default function FooterAddressView({
  shippingInfo,
  customShippingFormref,
}) {
  const [data, setData] = useState({});

  useEffect(() => {
    setData({
      firstName: shippingInfo?.shipFirstName || shippingInfo?.FirstName,
      lastName: shippingInfo?.shipLastName || shippingInfo?.LastName,
      address1:
        (shippingInfo?.shipAddress1 && shippingInfo?.shipAddress1) ||
        shippingInfo?.ShippingAddress1,
      address2:
        shippingInfo?.shipAddress2 || customShippingFormref?.ShippingAddress2,
      city: shippingInfo?.shipCity || shippingInfo?.ShippingCity,
      state:
        shippingInfo?.shipState || customShippingFormref?.ShippingState?.label,
      suite:
        shippingInfo?.ShippingSuite || customShippingFormref?.ShippingSuite,
      country:
        shippingInfo?.shipCountryName ||
        customShippingFormref?.ShippingCountry?.label,
      zipCode: shippingInfo?.shipZipcode || customShippingFormref?.ShippingZip,
      phone: shippingInfo?.shipPhone || customShippingFormref?.ShippingPhone,
    });
  }, [shippingInfo]);

  return (
    <>
      <div className="w-full mb-[30px] px-[13px] flex">
        <div className="border border-gray-border rounded-[5px] overflow-hidden w-full">
          <div className="bg-light-gray flex flex-wrap justify-between items-center pl-[10px] pr-[10px] pt-[6px] pb-[6px] rounded-t-[5px] overflow-hidden">
            <span className="text-normal-text">Ship to:</span>
          </div>
          <div className="p-[15px]">
            <div className="font-bold text-medium-text mb-[20px] last:mb-0">
              {data?.firstName + " " + data?.lastName}
            </div>
            <div className="text-normal-text mb-[20px] last:mb-0">
              {`${data?.address1} ${data?.address2 ? data?.address2 : ""}`}
            </div>
            {data?.suite && (
              <div className="text-normal-text mb-[20px] last:mb-0">
                {data?.suite}
              </div>
            )}
            <div className="text-normal-text mb-[20px] last:mb-0">
              {data?.city ? data?.city + ", " : ""}
              {data?.state ? data?.state + ", " : ""}
              {data?.zipCode}
            </div>
            <div className="text-normal-text mb-[20px] last:mb-0">
              {data?.country}
            </div>
            <div className="text-normal-text mb-[20px] last:mb-0">
              {data?.phone}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
