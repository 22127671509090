import axios from "axios";
import { toast } from "react-toastify";
import { serverError } from "utils/index";

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  function (config) {
    if (config?.data && config?.data?.files) {
      config.headers["Content-Type"] = "multipart/form-data";
    } else {
      config.headers["Content-Type"] = "application/json";
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

const responseInterceptor = (response) => {
  if (response && response.data.success && response.data) {
    return response.data;
  } else {
    if (response && response?.data && response?.data?.errors) {
      const serverErrorLog = serverError(response);

      const errorArr = serverErrorLog.split(";");

      if (serverErrorLog) {
        errorArr.forEach((errorStr) => {
          if (errorStr !== "record does not exist or has been deleted.") {
            return toast(errorStr);
          }
        });
      }
      return Promise.reject(response?.data?.errors);
    }
    return response?.data?.errors;
  }
};

const errInterceptor = (error) => {
  toast(
    `error from api ${error?.message ? JSON.stringify(error?.message) : ""}`
  );
  return Promise.reject(error);
};

axiosInstance.interceptors.response.use(responseInterceptor, errInterceptor);

export { axiosInstance };
