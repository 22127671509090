import { validationTypes } from "../constants";
import { validationMessages } from "../constants/messages";

export const checkValidation = ({ name, required, type, minLength }, value) => {

  // changing the order of validation will change the behavior of how validation works

  if (required) {
    if (!value) {
      return validationMessages.required[name] || "This field is required";
    }
  }
  if (!required) {
    if (!value) {
      return null;
    }
  }

  if (name === "email") {
    const emailRegex = /^[\w.-]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (!emailRegex.test(value)) {
      return validationMessages[name];
    }
  }
  // check for different types of validations
  if (type === validationTypes.alphabetic) {
    const validAlphabet = /^[a-zA-Z\s]+$/.test(value);
    if (!validAlphabet) {
      return validationMessages.invalidAlphabets;
    }
  }
  if (type === validationTypes.alphanumeric) {
    const validAlphaNumeric = /^[a-zA-Z0-9\s]+$/.test(value);
    if (!validAlphaNumeric) {
      return validationMessages.invalidAlphaNumeric;
    }
  }
  if (type === validationTypes.numeric) {
    const validNumbers = /^[0-9]+$/.test(value);
    if (!validNumbers) {
      return validationMessages.invalidNumbers;
    }
  }

  // check for minimum length
  if (minLength) {
    if (value.length < minLength) {
      return `Minimum ${minLength} characters required`
    }
  }

  return null;
};
