import React, { useEffect, useState } from "react";
import Required from "./Required";

const Select = ({
  label,
  required,
  options,
  id,
  handleChange,
  isReadOnly,
  currentPage,
  fieldValues,
  choiceValue,
  dependencies,
  parentId,
  value,
  defaultValue,
  formLabelFontSize,
}) => {
  const [val, setVal] = useState();

  useEffect(() => {
    setVal(value || choiceValue);
  }, [value, choiceValue]);

  useEffect(() => {
    //For setting default value as initial value in form field
    handleChange(id, choiceValue || defaultValue, {}, parentId);

    if (dependencies) {
      if (dependencies?.value === choiceValue) {
        const dependentElement = dependencies?.element;
        let elementAlreadyAdded = false;
        fieldValues.pages[currentPage].rows.forEach((row) => {
          if (row.elements.some((ele) => ele?.id === dependentElement?.id)) {
            elementAlreadyAdded = true;
            return;
          }
        });
        if (!elementAlreadyAdded) {
          fieldValues.pages[currentPage].rows.forEach((row, index) => {
            row.elements.forEach((element) => {
              if (element?.id === id) {
                element.value = choiceValue;
                fieldValues.pages[currentPage].rows.splice(index + 1, 0, {
                  elements: [dependentElement],
                });
              }
            });
          });
        }
      }
    }
  }, []);

  return (
    <div className="w-full  px-[15px] mb-[15px]" key={val}>
      <label
        className={`font-bold mb-[5px] ${formLabelFontSize ? formLabelFontSize : "text-normal-text"
          }`}
      >
        {label}
        {required && <Required />}
      </label>
      <div>
        <select
          disabled={isReadOnly}
          className="form-input py-[10px] disabled:bg-gray-200"
          onChange={(e) => {
            setVal(e.target.value);
            handleChange(id, e.target.value, {}, parentId);
          }}
          value={val}
        // defaultValue={choiceValue || defaultValue}
        >
          <option value="">Select Value</option>
          {Object.values(options).map((option, index) => (
            <option key={index} value={option}>{option}</option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default Select;
