import React from "react";
import Required from "./Required";
import ErrorMessage from "./ErrorMessage";
import { typeValues } from "../../constants";
import InfoButton from "../buttons/InfoButton";

const Input = ({
  id,
  label,
  required,
  placeholder,
  type,
  handleChange,
  validationErrors,
  validationType,
  numberOfCharacters = { min: "", max: "" },
  isReadOnly,
  helperText,
  parentId,
  value,
  isShow,
  name,
  formLabelFontSize,
}) => {
  if (!isShow)
    return <input className="form-input disabled:bg-gray-200 invisible" />;

  const renderInputElement = () => {
    switch (type) {
      case typeValues.password:
        return (
          <input
            value={value}
            placeholder={placeholder}
            type="password"
            className="form-input disabled:bg-gray-200 "
            disabled={isReadOnly}
            // defaultValue={defaultValue}
            minLength={numberOfCharacters?.min}
            maxLength={numberOfCharacters?.max}
            onChange={(e) => {
              handleChange(
                id,
                e.target.value,
                {
                  name: type,
                  type: validationType,
                  required,
                  minLength: Number(numberOfCharacters.min),
                },
                parentId
              );
            }}
          />
        );
      case typeValues.multipleLine:
        return (
          <textarea
            value={value}
            placeholder={placeholder}
            disabled={isReadOnly}
            rows={
              type === typeValues.singleLine
                ? "1"
                : type === typeValues.multipleLine
                  ? "5"
                  : "1"
            }
            className="form-input disabled:bg-gray-200 "
            // defaultValue={defaultValue}
            minLength={numberOfCharacters?.min}
            maxLength={numberOfCharacters?.max}
            onChange={(e) => {
              handleChange(
                id,
                e.target.value,
                {
                  name: type,
                  type: validationType,
                  required,
                  minLength: Number(numberOfCharacters.min),
                },
                parentId
              );
            }}
          />
        );
      default:
        return (
          <input
            value={value}
            placeholder={placeholder}
            type="text"
            className="form-input disabled:bg-gray-200 "
            disabled={isReadOnly}
            // defaultValue={defaultValue}
            minLength={numberOfCharacters?.min}
            maxLength={numberOfCharacters?.max}
            onChange={(e) => {
              handleChange(
                id,
                e.target.value,
                {
                  name: type || name?.toLowerCase(),
                  type: validationType,
                  required,
                  minLength: Number(numberOfCharacters.min),
                },
                parentId
              );
            }}
          />
        );
    }
  };

  return (
    <div className="w-full  px-[15px] mb-[15px]">
      <label
        className={`mb-[5px] font-bold ${formLabelFontSize ? formLabelFontSize : "text-normal-text"
          }`}
      >
        {label}
        {required && <Required />}
      </label>
      <div className="relative">
        {renderInputElement()}
        {helperText && <InfoButton text={helperText} />}
      </div>
      <ErrorMessage
        message={validationErrors.find((el) => el?.id === id)?.message}
      />
    </div>
  );
};

export default Input;
