import React from 'react'

export default function FooterFilledCustomFormView({ pages }) {
    const ignoreAbleKeys = ["heading", "Button", "Please select Captcha", "image", "form", "product"]

    function extractLabelAndValue(data) {
        let result = [];

        data.forEach(page => {
            page.rows.forEach(row => {
                row.elements.forEach(element => {
                    let label = element.fieldOptions.label;
                    let value = element.fieldOptions.value || null;
                    if (!ignoreAbleKeys.includes(label)) {
                        result.push({ label, value });
                    }
                });
            });
        });
        return result;
    }

    const formFieldsWithValues = extractLabelAndValue(pages)

    if (formFieldsWithValues.every((obj) => obj.value === null)) {
        return null
    }

    return (
        <>
            <h1 className='my-4 text-lg font-bold'>Customer Details.</h1>
            <div className="relative w-full pb-[20px] rounded-md">
                <div className="grid grid-cols-2 md:grid-cols-2 ">
                    {formFieldsWithValues && formFieldsWithValues.map((obj, index) => (<div className="mb-[10px] text-lg" key={index}>
                        <p className="flex text-gray-600 text-xs items-center">
                            <strong className="uppercase">{`${obj?.label}`}</strong>
                            : {obj?.label === "upload" ? <img src={`${obj?.value}`} alt="" className="ml-2 max-h-[40px]" /> : <span className="text-gray-800 text-sm ml-1">{obj?.value || "___"}</span>}</p>
                    </div>
                    ))}
                </div>
            </div>
        </>
    )
}
