import React, { useEffect, useState } from "react";
import Required from "./Required";

const RadioComponent = ({
  label,
  required,
  options,
  handleChange,
  id,
  fieldValues,
  currentPage,
  choiceValue,
  dependencies,
  parentId,
  value,
  formLabelFontSize,
  isTranspose,
}) => {
  const [val, setVal] = useState();
  useEffect(() => {
    if (dependencies) {
      if (dependencies?.value === choiceValue) {
        const dependentElement = dependencies?.element;
        let elementAlreadyAdded = false;
        fieldValues.pages[currentPage].rows.forEach((row) => {
          if (row.elements.some((ele) => ele.id === dependentElement?.id)) {
            elementAlreadyAdded = true;
            return;
          }
        });
        if (!elementAlreadyAdded) {
          fieldValues.pages[currentPage].rows.forEach((row, index) => {
            row.elements.forEach((element) => {
              if (element.id === id) {
                element.value = choiceValue;
                fieldValues.pages[currentPage].rows.splice(index + 1, 0, {
                  elements: [dependentElement],
                });
              }
            });
          });
        }
      }
    }
  }, []);

  useEffect(() => {
    setVal(value || choiceValue);
  }, [value, choiceValue]);

  const renderRadioButtons = () =>
    Object.values(options).map((option, index) => (
      <div className={`flex align-baseline ${isTranspose ? "mr-2" : ""}`} key={index}>
        <div className="mr-3">
          <input
            type="radio"
            value={option}
            checked={val === option}
            className="ml-3"
            name={id}
            onChange={(e) => {
              setVal(e.target.value);
              handleChange(id, e.target.value, {}, parentId);
            }}
          />
        </div>
        <label
          htmlFor={id}
          className={` ${formLabelFontSize ? formLabelFontSize : "text-normal-text"
            }`}
        >
          {option}
        </label>
      </div>
    ));
  return (
    <div className="w-full  px-[15px] mb-[15px]">
      <p className={`font-bold ${formLabelFontSize}`}>
        {label}
        {required && <Required />}
      </p>
      {isTranspose ? (
        <div className="flex flex-wrap">{renderRadioButtons()}</div>
      ) : (
        renderRadioButtons()
      )}
    </div>
  );
};

export default RadioComponent;
