import React from "react";

const PageTabs = ({ setCurrentPage, pageCount, currentPage }) => {
  return (
    <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 dark:text-gray-400 mb-5">
      {Array.from({ length: pageCount }, (_, index) => index).map((_, ind) => (
        <li className="mr-2" key={ind}>
          <button
            onClick={() => setCurrentPage(ind)}
            aria-current="page"
            className={`inline-block px-5 py-3 rounded-lg ${
              currentPage !== ind && "hover:text-gray-900"
            }  ${
              currentPage !== ind && "hover:bg-gray-100"
            } dark:hover:bg-gray-800 dark:hover:text-white ${
              currentPage === ind ? "text-white" : "text-gray-500"
            } ${currentPage === ind && "bg-blue-600"}`}
          >
            Page {ind + 1}
          </button>
        </li>
      ))}
    </ul>
  );
};

export default PageTabs;
