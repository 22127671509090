import { FormContext } from "contexts/FormContext";
import React, { useContext, useState } from "react";
import ProductSection from "./ProductSection";

const Product = ({
  pageContent,
  productLimitCount,
  products,
  promotionalProducts,
  storeId,
  promotionalProductCountRef,
  bundleRequiredData,
}) => {
  const { formData } = useContext(FormContext);
  const [eachTypeProductCount, seteachTypeProductCount] = useState({
    product: 0,
    promotionalProduct: 0,
  });

  const findProduct = (data) =>
    data.some((group) =>
      group?.elements?.some(
        (element) => element?.fieldOptions?.label === "product"
      )
    );

  return (
    <>
      {/* All products */}
      {formData?.formType === "filledUpForm" &&
        pageContent?.rows &&
        findProduct(pageContent?.rows) &&
        products?.length > 0 && (
          <ProductSection
            products={products}
            storeId={storeId}
            section="product"
            promotionalProductCountRef={promotionalProductCountRef}
            eachTypeProductCount={eachTypeProductCount}
            seteachTypeProductCount={seteachTypeProductCount}
            productLimitCount={productLimitCount}
          />
        )}

      {/* bundle products */}
      {formData?.formType === "filledUpForm" &&
        pageContent?.rows &&
        findProduct(pageContent?.rows) &&
        bundleRequiredData?.length > 0 && (
          <ProductSection
            products={bundleRequiredData}
            storeId={storeId}
            section="bundleProduct"
            promotionalProductCountRef={promotionalProductCountRef}
            eachTypeProductCount={eachTypeProductCount}
            seteachTypeProductCount={seteachTypeProductCount}
            productLimitCount={productLimitCount}
          />
        )}

      {/* promotional products */}
      {promotionalProducts && promotionalProducts.length ? (
        <ProductSection
          products={promotionalProducts}
          storeId={storeId}
          section="promotionalProduct"
          promotionalProductCountRef={promotionalProductCountRef}
          eachTypeProductCount={eachTypeProductCount}
          seteachTypeProductCount={seteachTypeProductCount}
          productLimitCount={productLimitCount}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default Product;
