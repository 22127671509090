import { useState, useContext, Fragment, useEffect, useMemo } from "react";
import { toast } from "react-toastify";

import { FormContext } from "../contexts/FormContext";

import { getProductSizeChartData } from "../service/FormService";

import SizeChart from "./sizeChart";
import { stringToJsonParser } from "../utils";

const Product = ({
  product,
  storeId,
  productIndex,
  section,
  promotionalProductCountRef,
  seteachTypeProductCount,
  eachTypeProductCount,
  variableForProductTypeSelecte,
}) => {
  const { cartItems, setCartItems, formData } = useContext(FormContext);

  const referenceSizeSequence = [
    "XS",
    "SM",
    "MD",
    "LG",
    "XL",
    "2Xl",
    "XXL",
    "3XL",
    "XXXL",
    "4XL",
    "XXXXL",
    "5XL",
    "XXXXXL",
  ];
  // const productImage = useMemo(
  //   () =>
  //     product?.productImage && Array.isArray(product?.productImage)
  //       ? product?.productImage[0]
  //       : "",
  //   [product?.productImage]
  // );

  const [productImage, setProductImage] = useState("");

  const getSortedAttributeSize = () => {
    if (
      product?.attributeOptionsSize &&
      Array.isArray(product?.attributeOptionsSize)
    ) {
      try {
        // Comparator function based on referenceSizeSequence
        function customSort(a, b) {
          return (
            referenceSizeSequence.indexOf(a.attributeOptionValue) -
            referenceSizeSequence.indexOf(b.attributeOptionValue)
          );
        }

        // Sort the availableSizes based on the referenceSizeSequence
        return product?.attributeOptionsSize.sort(customSort);
      } catch (error) {
        console.warn("found difficulty while size sorting ", error);

        return [];
      }
    } else {
      return [];
    }
  };

  const availableSizes = useMemo(getSortedAttributeSize, [
    product?.attributeOptionsSize,
  ]);

  const [selectedProductColor, setSelectedProductColor] = useState({
    attributeOptionName: "",
    attributeOptionValue: "",
    attributeOptionId: "",
  });
  const [selectedSize, setSelectedSize] = useState({
    size: "",
    sizeAttributeOptionId: "",
  });
  const [SizeChartData, setSizeChartData] = useState({
    data: null,
    toShow: false,
  });

  const handleSizeCheckboxChange = (size, sizeAttributeOptionId) => {
    setSelectedSize((old) =>
      old === size
        ? { size: "", sizeAttributeOptionId: "" }
        : { size, sizeAttributeOptionId }
    );
  };

  const ProductAlreadyInCart = () => {
    return cartItems.find((item) => item.productId == product?.id);
  };

  const handleAddProduct = (selectedProductColor, selectedSize) => {
    const productIncart = ProductAlreadyInCart();

    if (
      productIncart?.cartLogoPersonModel[0]?.attributeOptionId ===
      selectedSize?.sizeAttributeOptionId
    ) {
      return;
    }

    const cartPayload = {
      customerId: 0,
      productId: product.id,
      storeId: storeId, //parent store id
      isempLogin: false,
      ipAddress: "192.168.1.1",
      isForm: true,
      shoppingCartItemModel: {
        // product detail
        id: 0,
        price: section !== "promotionalProduct" ? product?.msrp || 0 : 0,
        quantity: 1,
        weight: 0,
        productType: product?.productTypeId, //
        name: product?.name,
        sku: product?.sku,
        discountPrice: 0,
        logoTitle: "",
        logogImagePath: productImage,
        perQuantity: 1,
        appQuantity: 1,
        status: 0,
        discountPercentage: 0,
        productCustomizationId: 0,
        itemNotes: "",
        isEmployeeLoginPrice: 0,
      },
      shoppingCartItemsDetailModels: [
        // color details
        {
          attributeOptionName: selectedProductColor?.attributeOptionName,
          attributeOptionValue: selectedProductColor?.attributeOptionValue,
          attributeOptionId: selectedProductColor?.attributeOptionId,
          swatch: selectedProductColor?.swatch,
        },
      ],
      cartLogoPersonModel: [
        // each size detail
        {
          id: 0,
          attributeOptionId: selectedSize?.sizeAttributeOptionId,
          attributeOptionValue: selectedSize?.size,
          // i have added this sku on the special request of Teja bhai.
          code: product?.sku,
          price: section !== "promotionalProduct" ? product?.msrp || 0 : 0,
          quantity: 1,
          estimateDate: new Date(),
          isEmployeeLoginPrice: 0,
        },
      ],
      cartLogoPersonDetailModels: [],
    };

    if (
      productIncart &&
      productIncart?.cartLogoPersonModel[0]?.attributeOptionId !==
        selectedSize?.sizeAttributeOptionId
    ) {
      setCartItems((prevCartItems) => {
        const filteredCartItem = prevCartItems.filter(
          (item) => item?.productId != product?.id
        );

        return [...filteredCartItem, cartPayload];
      });
    } else {
      // if ((promotionalProductCountRef.current + formData?.productLimitCount) > cartItems.length) {

      setCartItems((prev) => {
        const withOldCartItem = [...prev];
        if (!prev.some((old) => old.productId == cartPayload.productId)) {
          withOldCartItem.push(cartPayload);
        }
        return withOldCartItem;
      });
      // } else {
      //   toast(formData?.productLimitCount ? `you are only allowed to select ${formData?.productLimitCount} products` : "You are not allowed to select any product.");
      // }
    }
  };

  const handleAddToCart = () => {
    if (ProductAlreadyInCart()) {
      handleRemoveProduct();
      seteachTypeProductCount((prev) => ({
        ...prev,
        [variableForProductTypeSelecte]:
          prev[variableForProductTypeSelecte] - 1,
      }));
    } else {
      if (
        formData?.productLimitCount >
        (eachTypeProductCount[variableForProductTypeSelecte]
          ? eachTypeProductCount[variableForProductTypeSelecte]
          : 0)
      ) {
        handleAddProduct(selectedProductColor, selectedSize);
        seteachTypeProductCount((prev) => ({
          ...prev,
          [variableForProductTypeSelecte]: prev[variableForProductTypeSelecte]
            ? prev[variableForProductTypeSelecte] + 1
            : 1,
        }));
      } else {
        toast(
          formData?.productLimitCount
            ? `You are only allowed to select ${formData?.productLimitCount} products in each type of product.`
            : "You are not allowed to select any product."
        );
      }
    }
  };

  const handleRemoveProduct = () => {
    setCartItems((prevCartItems) => {
      const filteredCartItem = prevCartItems.filter(
        (item) => item?.productId != product?.id
      );

      return filteredCartItem;
    });

    setSelectedProductColor({
      attributeOptionName: "",
      attributeOptionValue: "",
      attributeOptionId: "",
    });
    setSelectedSize({
      size: "",
      sizeAttributeOptionId: "",
    });
  };

  const findSizeChartOFThisProduct = async (pId) => {
    const ProductSizeChartData = await getProductSizeChartData(pId);

    if (ProductSizeChartData?.success && ProductSizeChartData?.data) {
      const allSizeChartData = {
        ...ProductSizeChartData?.data,
        sizeChartRange: ProductSizeChartData?.data.sizeChartRange
          .split(",")
          .map((elem) => elem),
        measurements: ProductSizeChartData?.data?.measurements
          .split(",")
          .map((elem) => elem),
        sizeChartView: stringToJsonParser(
          ProductSizeChartData?.data?.sizeChartView
        ).data[0],
      };

      setSizeChartData({
        toShow: true,
        data: allSizeChartData,
      });
    } else {
      setSizeChartData({
        toShow: true,
        data: null,
      });
    }
  };

  const handleAttributeColorCheckboxChange = (checked, color) => {
    if (checked) {
      setSelectedProductColor({
        attributeOptionName: color.attributeOptionValue,
        attributeOptionValue: color.attributeOptionValue,
        attributeOptionId: color.attributeOptionId,
      });
    } else {
      setSelectedProductColor({
        attributeOptionName: "",
        attributeOptionValue: "",
        attributeOptionId: "",
      });
    }
  };

  useEffect(() => {
    if (
      product?.attributeOptionsColor &&
      product?.attributeOptionsColor.length > 0
    ) {
      const colorPayoad = {
        attributeOptionName:
          product?.attributeOptionsColor[0].attributeOptionValue,
        attributeOptionValue:
          product?.attributeOptionsColor[0].attributeOptionValue,
        attributeOptionId: product?.attributeOptionsColor[0].attributeOptionId,
      };

      setSelectedProductColor(colorPayoad);

      if (section === "promotionalProduct") {
        if (availableSizes && availableSizes.length > 0) {
          const sizePayload = {
            size: availableSizes[0].attributeOptionValue,
            sizeAttributeOptionId: availableSizes[0]?.attributeOptionId,
          };
          handleAddProduct(colorPayoad, sizePayload);
        }
      }
    }
  }, [product?.attributeOptionsColor]);

  useMemo(() => {
    // if (
    //   product?.productImage &&
    //   Array.isArray(product?.attributeOptionsColor)
    // ) {
    //   setProductImage(product?.attributeOptionsColor[0]?.swatch);
    // } else {
    setProductImage(
      product?.productImage && Array.isArray(product?.productImage)
        ? product?.productImage[0]
        : ""
    );
    // }
  }, [product?.productImage]);

  return (
    <div>
      <div className="relative p-[20px] rounded-[8px]">
        {/* ---------- product image ---------- */}
        <div className="border border-light-gray rounded-[8px] overflow-hidden w-full mb-[10px]">
          <img
            src={process.env.REACT_APP_imageBaseUrl + productImage}
            alt=""
            className="product-listing-img h-full w-auto inline-block max-h-[348px] aspect-square object-contain mx-auto"
          />
        </div>
        <div class="text-center h-[100px] cursor-pointer mt-[3px] mb-[3px]">
          {/* ---------- brand logo ---------- */}
          {product?.brandImage ? (
            <a>
              <img
                src={process.env.REACT_APP_imageBaseUrl + product?.bandWLogoUrl}
                alt=""
                class="inline-block max-h-full"
              />
            </a>
          ) : (
            <></>
          )}
        </div>
        <div className="pl-[8px] pr-[8px]">
          {/* ---------- product name ---------- */}
          <div className="mt-[8px] h-[44px] text-ellipsis font-semibold overflow-hidden line-clamp-2 tracking-[1.4px]">
            {product.name}
          </div>
          {/* ---------- product msrp ---------- */}
          {formData?.showPrice ? (
            <span className="font-semibold ">MSRP ${product.msrp}</span>
          ) : (
            <></>
          )}
        </div>

        {/* ---------- product size and varient ---------- */}
        {section === "promotionalProduct" ? (
          <></>
        ) : (
          <>
            <div className="mt-[12px]">
              {"Please Select "}
              {Array.isArray(product?.attributeOptionsColor) &&
              product?.attributeOptionsColor.length > 1
                ? "Color and"
                : " "}
              {" Size"}
              <div className="flex flex-wrap justify-center items-center mx-auto px-[10px] mt-[5px]">
                {Array.isArray(product?.attributeOptionsColor) &&
                  product?.attributeOptionsColor.map((color, index) => {
                    return (
                      <Fragment key={index}>
                        <input
                          type="checkbox"
                          id={`color-checkbox-${section}-${productIndex}-${index}`}
                          className="hidden"
                          onChange={(e) =>
                            (product?.attributeOptionsColor.length > 1 ||
                              e.target.checked) &&
                            handleAttributeColorCheckboxChange(
                              e.target.checked,
                              color
                            )
                          }
                          checked={
                            selectedProductColor?.attributeOptionId ===
                            color?.attributeOptionId
                          }
                        />
                        <label
                          htmlFor={`color-checkbox-${section}-${productIndex}-${index}`}
                          className={`mr-[4px] h-[40px] border-2 cursor-pointer hover:border-blue-500 ${
                            selectedProductColor?.attributeOptionId ==
                            color?.attributeOptionId
                              ? " border-blue-500"
                              : ""
                          }`}
                        >
                          <img
                            src={`${process.env.REACT_APP_imageBaseUrl}${color.swatch}`}
                            alt={color.attributeOptionValue}
                            className="max-h-full"
                            onMouseOver={() => {
                              setProductImage(color.swatch);
                            }}
                            onMouseLeave={() => {
                              if (
                                selectedProductColor?.attributeOptionId ===
                                color?.attributeOptionId
                              ) {
                                setProductImage(productImage);
                              } else {
                                const temp = product?.attributeOptionsColor
                                  ?.length
                                  ? product?.attributeOptionsColor?.find(
                                      (i) =>
                                        i?.attributeOptionValue ===
                                        selectedProductColor?.attributeOptionValue
                                    )?.swatch ||
                                    product?.attributeOptionsColor[0]?.swatch // fallback if not found
                                  : product?.attributeOptionsColor?.[0]
                                      ?.swatch || color?.swatch; // fallback to first color or current one

                                setProductImage(temp || color?.swatch); // fallback to the current color swatch
                              }
                            }}
                            onClick={() => {
                              if (
                                selectedProductColor?.attributeOptionId ===
                                color?.attributeOptionId
                              ) {
                                return; // Prevent re-selection
                              }
                              setProductImage(color?.swatch);
                            }}
                          />
                        </label>
                      </Fragment>
                    );
                  })}
              </div>
            </div>
            <div className="pt-4 flex gap-4 flex-wrap justify-center items-center mx-auto px-[10px]">
              {/* Available Sizes: */}
              <div className="flex flex-wrap gap-2 items-center justify-center">
                {Array.isArray(availableSizes) &&
                  availableSizes.map((item, index) => (
                    <Fragment key={index}>
                      <input
                        type="checkbox"
                        id={`size-checkbox-${section}-${productIndex}-${index}`}
                        key={productIndex}
                        className="hidden"
                        checked={
                          selectedSize?.sizeAttributeOptionId ===
                          item?.attributeOptionId
                        }
                        onChange={(e) =>
                          handleSizeCheckboxChange(
                            item?.attributeOptionValue,
                            item?.attributeOptionId
                          )
                        }
                      />
                      <label
                        htmlFor={`size-checkbox-${section}-${productIndex}-${index}`}
                        className={`px-0.5 border-2 hover:border-blue-400 cursor-pointer ${
                          selectedSize?.sizeAttributeOptionId ===
                          item?.attributeOptionId
                            ? " border-blue-500"
                            : ""
                        }`}
                      >
                        {item?.attributeOptionValue}
                      </label>
                    </Fragment>
                  ))}
              </div>
            </div>
          </>
        )}

        {section !== "promotionalProduct" && (
          <>
            <div
              className="mt-[10px] font-bold min-w-3/6 mx-auto  py-2 px-4  cursor-pointer hover:underline"
              onClick={() => findSizeChartOFThisProduct(product.id)}
            >
              Size Chart
            </div>

            {SizeChartData.toShow && (
              <SizeChart
                SizeChartData={SizeChartData.data}
                setSizeChartData={setSizeChartData}
              />
            )}
          </>
        )}

        {section === "promotionalProduct" ? (
          <></>
        ) : (
          <div className="flex gap-4">
            <button
              type="button"
              disabled={
                !selectedProductColor?.attributeOptionId || !selectedSize?.size
              }
              className={`mt-[10px] btn btn-md btn-primary tracking-[1.4px] font-normal min-w-3/6 mx-auto  py-2 px-4 text-sm ${
                !selectedProductColor?.attributeOptionId || !selectedSize?.size
                  ? "opacity-20 "
                  : ""
              } `}
              onClick={handleAddToCart}
            >
              {ProductAlreadyInCart() ? "Remove From" : "Add To"} Cart
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Product;
