import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import router from "./router/router";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

function App() {
  const formField = useSelector((store) => store.TempData);
  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          href={`${process.env.REACT_APP_imageBaseUrl}/${
            process.env.REACT_APP_Image_Directory
          }/${1}/store/${formField?.formField?.formsId}/css/${
            formField?.formField?.formsId
          }.css?${Math.random()}`}
        />
        <link
          rel="stylesheet"
          type="text/css"
          href={`${process.env.REACT_APP_imageBaseUrl}/${
            process.env.REACT_APP_Image_Directory
          }/${1}/store/theme.css?${Math.random()}`}
        />
        <link
          rel="stylesheet"
          type="text/css"
          href={`${process.env.REACT_APP_imageBaseUrl}/${
            process.env.REACT_APP_Image_Directory
          }/${1}/store/forms.css?${Math.random()}`}
        />
      </Helmet>
      <ToastContainer
        id="myContainer"
        stacked
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <RouterProvider router={router} />
    </>
  );
}

export default App;
