import React, { useState } from "react";

const InfoButton = ({ text }) => {
  const [showInfo, setShowInfo] = useState(false);
  return (
    <>
      <div
        className="absolute top-2 right-5"
        onMouseEnter={() => setShowInfo(true)}
        onMouseLeave={() => setShowInfo(false)}
      >
        <button
          className=""
          onFocus={() => setShowInfo(true)}
          onBlur={() => setShowInfo(false)}
        >
          <span className="material-icons-outlined ml-2 text-base">info</span>
        </button>
      </div>
      {showInfo && (
        <div className="z-10 absolute top-[80%] right-6">
          <div className="bg-slate-100 p-2 overflow-hidden mt-2">{text}</div>
        </div>
      )}
    </>
  );
};

export default InfoButton;
