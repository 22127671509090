import React from "react";

export default function FooterProductView({ cartItemsObj }) {
  return (
    <>
      <div className="p-[15px] last:mb-0">
        <div className="flex flex-wrap">
          <div className="md:w-3/12 w-full px-[13px]">
            <div className="mb-[20px]">
              
                <img
                  src={`${process.env.REACT_APP_imageBaseUrl}${cartItemsObj?.shoppingCartItemModel?.logogImagePath}`}
                  alt=""
                  className="aspect-square object-contain"
                />

            </div>
          </div>
          <div className="md:w-9/12 w-full px-[13px]">
            <div className="flex flex-wrap justify-between mx-[-13px] mb-[30px]">
              <div className="lg:w-9/12 md:w-8/12 w-full px-[13px]">
                <div className="mb-[10px]">
                  <h1 className="font-bold text-medium-text">
                    {cartItemsObj?.shoppingCartItemModel?.name}
                  </h1>
                </div>
                <div className="mb-[10px] text-normal-text">
                  SKU: <span>{cartItemsObj?.shoppingCartItemModel?.sku}</span>
                </div>
                <div className="mb-[10px] text-normal-text">
                  {"Color: "}
                  <span>
                    {
                      cartItemsObj?.shoppingCartItemsDetailModels[0]
                        ?.attributeOptionValue
                    }
                  </span>
                </div>
                <div className="mb-[10px] text-normal-text">
                  {"Size: "}
                  <span>
                    {cartItemsObj?.cartLogoPersonModel[0]?.attributeOptionValue}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
