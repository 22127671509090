import React, { useMemo } from "react";

const Header = ({ logoImageUrl, position, formThemeData }) => {
  const logoPositionClass = useMemo(() => {
    if (!position || position.includes("center")) return "justify-center";
    if (position.includes("left")) return "justify-start";
    if (position.includes("right")) return "justify-end";
  }, [position]);

  return (
    <div className="border-b bg-white border-b-gray-border">
      <div className="container mx-auto">
        <div
          className={`flex flex-wrap  py-[15px] md:py-[2px]  ${logoPositionClass}`}
        >
          <div className="brand-logo flex">
            <div className={`bg-[#ffffff] flex p-[15px]`}>
              {logoImageUrl && (
                <img
                  alt="logo"
                  src={logoImageUrl || ""}
                  className="h-auto max-w-[300px]"
                />
              )}
            </div>
          </div>
          <div className="flex items-center">
            {/* <ul className="flex items-center gap-x-[20px]">
              <li>
              <a className="gap-x-[16px] text-[14px] flex items-center"><span className="hidden md:block">HOME
              </span> <span><svg width={28} height={31} viewBox="0 0 28 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.27002 29.22H10.03V17.46H18.49V29.22H26.25V11.21L14.26 2.12L2.27002 11.2V29.22ZM0.97998 30.5V10.55L14.25 0.5L27.52 10.57V30.5H17.1899V18.74H11.3V30.5H0.969971H0.97998Z" fill="black" />
              </svg>
              </span>
              </a>
              </li>
              <li>
              <a data-modal-toggle="FAQModal" className="gap-x-[16px] text-[14px] flex items-center"><span className="hidden md:block">FAQ
              </span> <span><svg width={31} height={31} viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.37 24.09C15.67 24.09 15.92 23.99 16.13 23.78C16.34 23.57 16.4399 23.32 16.4399 23.02C16.4399 22.72 16.34 22.47 16.13 22.26C15.92 22.05 15.67 21.95 15.37 21.95C15.07 21.95 14.82 22.05 14.61 22.26C14.4 22.47 14.3 22.72 14.3 23.02C14.3 23.32 14.4 23.57 14.61 23.78C14.82 23.99 15.07 24.09 15.37 24.09ZM14.6899 18.69H15.83C15.89 18 16.05 17.44 16.3 17C16.56 16.56 17 16.01 17.64 15.35C18.43 14.58 19 13.89 19.35 13.29C19.7 12.69 19.87 12.02 19.87 11.28C19.87 10 19.4301 8.96 18.5601 8.17C17.6901 7.38 16.6499 6.99 15.4399 6.99C14.3199 6.99 13.36 7.29 12.55 7.9C11.74 8.51 11.13 9.2 10.71 9.96L11.87 10.44C12.19 9.77 12.61 9.22 13.13 8.78C13.66 8.34 14.39 8.12 15.34 8.12C16.54 8.12 17.3999 8.45 17.9399 9.1C18.4799 9.76 18.75 10.48 18.75 11.26C18.75 11.88 18.59 12.41 18.27 12.87C17.95 13.33 17.54 13.8 17.02 14.3C16.12 15.16 15.5 15.93 15.17 16.6C14.84 17.27 14.6801 17.96 14.6801 18.68L14.6899 18.69ZM15.27 30.5C13.2 30.5 11.24 30.11 9.42004 29.32C7.59004 28.53 6.00002 27.46 4.65002 26.11C3.30002 24.76 2.22994 23.17 1.43994 21.35C0.649941 19.53 0.26001 17.58 0.26001 15.51C0.26001 13.44 0.649941 11.48 1.43994 9.66C2.22994 7.83 3.30002 6.24 4.65002 4.89C6.00002 3.54 7.59003 2.47 9.41003 1.68C11.23 0.89 13.18 0.5 15.25 0.5C17.32 0.5 19.28 0.89 21.1 1.68C22.93 2.47 24.52 3.54 25.87 4.89C27.22 6.24 28.29 7.83 29.08 9.65C29.87 11.47 30.26 13.42 30.26 15.49C30.26 17.56 29.87 19.52 29.08 21.34C28.29 23.17 27.22 24.76 25.87 26.11C24.52 27.46 22.93 28.53 21.11 29.32C19.29 30.11 17.34 30.5 15.27 30.5ZM15.26 29.38C19.12 29.38 22.4 28.03 25.1 25.34C27.8 22.64 29.15 19.36 29.15 15.5C29.15 11.64 27.8 8.36 25.11 5.66C22.41 2.96 19.13 1.61 15.27 1.61C11.41 1.61 8.13005 2.96 5.43005 5.65C2.73005 8.35 1.38 11.63 1.38 15.49C1.38 19.35 2.73004 22.63 5.42004 25.33C8.12004 28.03 11.4 29.38 15.26 29.38Z" fill="black" />
              </svg>
              </span>
              </a>
              </li>
              <li>
              <a className="gap-x-[16px] text-[14px] flex items-center"><span className="hidden md:block">MY CART
              </span> <span><svg width={24} height={25} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.84998 25C7.32998 25 6.90005 24.83 6.55005 24.48C6.20005 24.13 6.02002 23.7 6.02002 23.18C6.02002 22.66 6.19004 22.23 6.54004 21.88C6.89004 21.53 7.31997 21.35 7.83997 21.35C8.35997 21.35 8.79001 21.52 9.14001 21.87C9.49002 22.22 9.67004 22.65 9.67004 23.17C9.67004 23.69 9.50002 24.12 9.15002 24.47C8.80002 24.82 8.36998 25 7.84998 25ZM20.49 25C19.97 25 19.5399 24.83 19.1899 24.48C18.8399 24.13 18.66 23.7 18.66 23.18C18.66 22.66 18.8301 22.23 19.1801 21.88C19.5301 21.53 19.96 21.35 20.48 21.35C21 21.35 21.43 21.52 21.78 21.87C22.13 22.22 22.3101 22.65 22.3101 23.17C22.3101 23.69 22.14 24.12 21.79 24.47C21.44 24.82 21.01 25 20.49 25ZM5.13 2.75H22.29C22.9 2.75 23.36 3 23.67 3.49C23.98 3.98 24 4.49 23.73 5.01L20.11 11.63C19.94 11.92 19.7 12.15 19.41 12.33C19.12 12.5 18.81 12.59 18.47 12.59H8.77002L7.01001 15.82C6.82001 16.1 6.81999 16.4 6.98999 16.72C7.15999 17.04 7.42002 17.21 7.77002 17.21H22.29V18.1H7.82996C7.09996 18.1 6.55996 17.8 6.20996 17.21C5.85996 16.62 5.84994 16.02 6.18994 15.4L8.34998 11.6L3.23999 0.889999H0.839966V0H3.81006L5.12 2.75H5.13Z" fill="black" />
              </svg>
              </span>
              </a>
              </li>
              </ul> */}
          </div>
        </div>
        <div className="py-4 hidden">
          <a href={"/?todo=clearcache"}>Clear Cache</a>
        </div>
      </div>
    </div>
  );
};

export default Header;
