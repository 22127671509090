import React, { useEffect, useState } from "react";
import Required from "./Required";

const CheckBox = ({
  options,
  required,
  handleChange,
  id,
  label,
  defaultValue,
  parentId,
  value,
  formLabelFontSize,
  isTranspose,
}) => {
  const [selectedValues, setSelectedValues] = useState([]);
  useEffect(() => {
    if (value) {
      setSelectedValues(value);
    } else if (defaultValue) {
      setSelectedValues([defaultValue.toLowerCase()]);
    }
  }, [defaultValue]);

  const handleCheckboxChange = (e) => {
    const value = e.target.value;
    if (e.target.checked) {
      const newValues = [...selectedValues, value.toLowerCase()];
      setSelectedValues(newValues);
      handleChange(id, newValues, {}, parentId);
    } else {
      const filteredValues = selectedValues.filter((val) => val !== value);
      setSelectedValues(filteredValues);
      handleChange(id, filteredValues, {}, parentId);
    }
  };

  const renderOptions = () =>
    Object.values(options).map((option, index) => (
      <div
        className={`flex align-baseline ${
          isTranspose ? "flex-row-reverse mr-3" : ""
        }`}
        key={index}
      >
        <div className="mr-3">
          <input
            type="checkbox"
            value={option.toLowerCase()}
            className="ml-3"
            checked={selectedValues.includes(option.toLowerCase())}
            onChange={handleCheckboxChange}
          />
        </div>
        <label
          className={`${
            formLabelFontSize ? formLabelFontSize : "text-normal-text"
          } ${isTranspose ? "ml-2" : ""}`}
        >
          {option}
        </label>
      </div>
    ));

  return (
    <div className={`px-[15px] mb-[15px]`}>
      <p className={`font-bold ${formLabelFontSize}`}>
        {label} {required && <Required />}
      </p>
      {isTranspose ? (
        <div className="flex flex-wrap">{renderOptions()}</div>
      ) : (
        renderOptions()
      )}
    </div>
  );
};

export default CheckBox;
