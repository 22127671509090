import React from 'react'

const ErrorMessage = ({ message }) => {
  return (
    <>
      {message && <div className='text-red-500 text-small-text mt-1 '>{message}</div>}
    </>

  )
}

export default ErrorMessage