export const checkRequiredFieldsEntered = (fieldValues) => {
  if (!fieldValues) {
    return true;
  }
  let isEntered = true;
  let names = { firstName: "", lastName: "", email: "" };
  fieldValues.pages.forEach((page) => {
    page.rows.forEach((row) => {
      row.elements.forEach((element) => {
        if (element.name === "button") {
          if (element?.fieldOptions?.value) {
            const formsAdded = element.fieldOptions.value;
            formsAdded.forEach((form) => {
              form.forEach((rows) => {
                rows.forEach((row) => {
                  row.elements.forEach((elem) => {
                    if (elem.fieldOptions.isRequired && !elem.fieldOptions.value) {
                      isEntered = false;
                    }
                    if (elem?.fieldOptions?.label == "First Name")
                      names.firstName = elem?.fieldOptions?.value;
                    if (elem?.fieldOptions?.label == "Last Name")
                      names.lastName = elem?.fieldOptions?.value;
                    if (elem?.fieldOptions?.label == "Email Address")
                      names.email = elem?.fieldOptions?.value;
                  });
                });
              });
            });
          }
        } else {
          if (element.fieldOptions.isRequired && !element.fieldOptions.value) {
            isEntered = false;
          }
        }
      });
    });
  });
  return { isValid: isEntered, names };
};

export const stringToJsonParser = (ourString) => {
  if (typeof ourString !== "string") {
    return {
      error: "Error while parsing the string",
      data: {},
      success: false,
    };
  }
  try {
    const ourJson = JSON.parse(ourString);
    return { error: null, data: ourJson, success: true };
  } catch (error) {
    return { error: error, data: {}, success: false };
  }
};

export const paginateArray = (array = [], page_size = 100, page_number = 1) => {
  if (array && array.length > 0) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  }
  return [];
};

export const serverError = (response) => {
  const nested = (data) => {
    let errors = {};
    Object.keys(data).map((key, index) => {
      let value = data[key];
      if (
        Object.keys(data).length > 0 &&
        typeof value !== "object" /*  && Array.isArray(value) */
      ) {
        errors = { ...errors, [key]: value };
      } else {
        errors = { ...errors, ...nested(value) };
      }
      return errors;
    });
    return errors;
  };

  let errors = {};
  if (response.data.errors) {
    // return Object.values(response.data.errors).join("<br/> ");
    Object.keys(response.data.errors).map((key, index) => {
      let value = response.data.errors[key];

      if (
        Object.keys(response.data.errors).length > 0 &&
        typeof value !== "object" &&
        !Array.isArray(value)
      ) {
        errors = { ...errors, [key]: value };
      } else {
        errors = { ...errors, ...nested(value) };
        // nested(value);
      }
      return errors;
    });
  }
  return Object.values(errors)
    .filter((value) => value)
    .join(";");
};
