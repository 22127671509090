import React from "react";

const Button = ({
  label,
  icon,
  id,
  fieldValues,
  currentPage,
  dependencies,
  setFieldValues,
  classN,
}) => {
  const handleAddDependentForm = () => {
    const newFieldValues = JSON.parse(JSON.stringify(fieldValues));
    newFieldValues.pages[currentPage].rows.forEach((row) => {
      row.elements.forEach((element) => {
        if (element.id === id && dependencies?.value) {
          const value = JSON.parse(dependencies.value);
          const formToAdd = value.pages[0];
          if (element?.fieldOptions?.value?.length) {
            element.fieldOptions.value.push([formToAdd.rows]);
            element.fieldOptions.value.forEach((form, ind) => {
              form.forEach((rows) => {
                rows.forEach((row) => {
                  row.elements.forEach((elem) => {
                    elem.id = `${ind}-${elem.id}`;
                  });
                });
              });
            });
          } else {
            element.fieldOptions.value = [[formToAdd.rows]];
          }
        }
      });
    });
    setFieldValues(newFieldValues);
  };
  return (
    <button
      className={`${classN} flex justify-center items-center whitespace-nowrap btn btn-secondary max-h-10 mt-6 w-auto `}
      onClick={handleAddDependentForm}
    >
      {icon && <span className="material-icons mr-2 ">{icon}</span>}

      <span className="flex w-auto">{label}</span>
    </button>
  );
};

export default Button;
