import { frontBaseUrl } from "../constants";
import { axiosInstance } from "../utils/interceptor";

export const getFormFields = (url) => {
  return axiosInstance.post(
    `${frontBaseUrl}/FormBuilderFormField/GetFormBuilderFormFields`,
    {
      url,
    }
  );
};

export const getThemeConfigs = (formId, configName) => {
  return axiosInstance.post(
    `${frontBaseUrl}/FormBuilderThemeConfigs/GetListByFormsId.json`,
    {
      formId,
      configName,
    }
  );
};

export const getFormBuilder = (url) => {
  return axiosInstance.post(`${frontBaseUrl}/FormBuilder/GetFormBuilder`, {
    url,
  });
};

export const fetchProducts = (formId) => {
  return axiosInstance.post(
    `${frontBaseUrl}/FormBuilderFormProduct/list.json`,
    { formId }
  );
};

export const fetchPromotionalProducts = (formId, productids) => {
  return axiosInstance.post(
    `${frontBaseUrl}/FormBuilderFormProduct/getlistformbuilderpromotionalproduct.json`,
    {
      formId: formId,
      productids: productids,
    }
  );
};

export const createFilledForms = async (payload) => {
  const response = await axiosInstance.post(
    `${frontBaseUrl}/FormBuilderFilledForms/create.json`,
    payload
  );
  return response;
};

export const addProductToCart = async (payload) => {
  try {
    const response = await axiosInstance.post(
      `${frontBaseUrl}/Store/addtocart.json`,
      payload
    );

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const addOrder = async (payload) => {
  try {
    const response = await axiosInstance.post(
      `${frontBaseUrl}/Order/addorder.json`,
      payload
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const uplaodImage = async (payload, folderPath) => {
  const res = await axiosInstance.post(
    `${frontBaseUrl}/upload/image?folderPath=${folderPath}`,
    {
      files: payload,
    }
  );
  return res;
};

export const getStoreDetailsByDomain = async (url) => {
  try {
    const res = await axiosInstance.post(
      `${frontBaseUrl}/Store/getstorebydomain.json`,
      {
        url: url,
      }
    );
    return res;
  } catch (error) {
    return null;
  }
};

export const getProductSizeChartData = async (pId) => {
  try {
    const res = await axiosInstance.get(
      `${frontBaseUrl}/StoreProduct/getsizechartbyproductid/${pId}.json`
    );
    return res;
  } catch (error) {
    return null;
  }
};

export const decryptPassword = async (passwordString) => {
  try {
    const res = await axiosInstance.post(
      `${frontBaseUrl}/DataProtectServices/decrypt.json?password=${passwordString}`
    );
    return res;
  } catch (error) {
    return null;
  }
};

const getThemeConfigsForFormBuilder = async (formId, type) => {
  try {
    const res = await axiosInstance.get(
      `${frontBaseUrl}/CmsStoreThemeConfigs/getstorethemeconfigs/${formId}/${type}.json`
    );
    return res;
  } catch (error) {
    return null;
  }
};

export const fetchFormThemeAllConfigs = async (formId) => {
  const types = [
    "css_type",
    "main_theme_config",
    "contact_info",
    "custom_theme_footer",
  ];

  const promises = types.map((type) =>
    getThemeConfigsForFormBuilder(formId, type)
  );

  try {
    const results = await Promise.all(promises);

    const responseObject = types.reduce((acc, type, index) => {
      acc[type] = results[index]?.data || {};
      return acc;
    }, {});
    return responseObject;
  } catch (error) {
    console.error("Error fetching theme configs:", error);
    return null;
  }
};

export const getLocationDataBasedOnZipCode = async (zipCode) => {
  try {
    const res = await axiosInstance.get(
      `${frontBaseUrl}/City/getcitystatecountrybyzipCode/${zipCode}.json`
    );
    return res;
  } catch (error) {
    return null;
  }
};

export const getStateByCountryName = async (countryName) => {
  try {
    const res = await axiosInstance.get(
      `${frontBaseUrl}/StateMaster/getstatebycountryname/${countryName}.json`
    );
    return res;
  } catch (error) {
    return null;
  }
};

export const getCustomerCountry = async () => {
  try {
    const res = await axiosInstance.post(
      `${frontBaseUrl}/StoreCustomer/getcustomercountry.json`
    );
    return res;
  } catch (error) {
    return null;
  }
};

export const getCustomerStateByCountryId = async (countryId) => {
  try {
    const res = await axiosInstance.get(
      `${frontBaseUrl}/StoreCustomer/getcustomerstatebycountryid/${countryId}.json`
    );
    return res;
  } catch (error) {
    return null;
  }
};

export const getBundleListByStoreId = async (formStoreId) => {
  try {
    const res = await axiosInstance.post(
      `${frontBaseUrl}/FormBuilderFormProduct/getbundlelistbystoreid.json`,
      { formStoreId }
    );
    return res;
  } catch (error) {
    return null;
  }
};

export const clearCache = async (storeid, categoryid) => {
  try {
    const url = `${frontBaseUrl}/Category/clearcategorycatch/${storeid}/${categoryid}.json`;
    const response = await axiosInstance.get(url);
    return response;
  } catch (error) {
    const message =
      error?.message || "Cache not cleared with category id: " + categoryid;

    throw new Error(message);
  }
};

export const getShippingMethod = async (smObj) => {
  try {
    const url = `${frontBaseUrl}/Store/GetShippingmethod`;
    const response = await axiosInstance.post(url, smObj);
    return response;
  } catch (error) {
    throw null;
  }
};
