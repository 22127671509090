import React, { useState } from "react";
import Required from "./Required";
import ErrorMessage from "./ErrorMessage";
import InfoButton from "../buttons/InfoButton";
import { useContext } from "react";
import { FormContext } from "../../contexts/FormContext";
import { MEDIA_BASE_URL } from "../../constants";
import { alertMessages } from "../../constants/messages";
import { uplaodImage } from "../../service/FormService";
import Loader from "../loader/Loader";
import { toast } from "react-toastify";

const Upload = ({
  label,
  required,
  formLabelFontSize,
  helperText,
  isReadOnly,
  validationErrors,
  id,
  handleChange,
  parentId,
}) => {
  const [fileToUpload, setFileToUpload] = useState(null);
  const { storeId, formId } = useContext(FormContext);
  const [isLoading, setIsLoading] = useState(false);
  const handleFileChange = (e) => {
    if (
      e.target.files &&
      !["image/png", "image/jpeg", "image/jpg"].includes(e.target.files[0].type)
    ) {
      toast("Please choose png, jpeg or jpg image only")

      return setFileToUpload(null);
    }
    const file = {
      name: e?.target.files[0].name,
      file: e?.target.files[0],
      previewURL: URL.createObjectURL(e.target.files[0]),
    };

    setFileToUpload(file);
  };

  const uploadFile = async () => {
    try {
      setIsLoading(true);
      const folder = `/${storeId}/form/${formId}/`;
      const uploadedUrl = await uplaodImage(fileToUpload?.file, folder);

      if (uploadedUrl && uploadedUrl?.success && uploadedUrl?.data) {
        setFileToUpload((prev) => ({ ...prev, isUploaded: true }));
        handleChange(id, MEDIA_BASE_URL + uploadedUrl?.data, {}, parentId);
        setIsLoading(false);
      }

    } catch (error) {
      setIsLoading(false);
      setFileToUpload(null);
      toast(alertMessages.errorOccured);
    }
  };

  const handleCancel = () => {
    setFileToUpload(null);
    if (fileToUpload?.isUploaded) handleChange(id, "", {}, parentId);
  };

  return (
    <>
      {isLoading && <Loader />}

      <div className="w-full px-[15px] mb-[15px]">
        <label
          className={`font-bold mb-[5px] ${formLabelFontSize ? formLabelFontSize : "text-normal-text"
            }`}
        >
          {label}
          {required && <Required />}
        </label>
        {fileToUpload?.previewURL ? (
          <div className="form-input flex">

            <img
              src={fileToUpload?.previewURL}
              width="50px"
              className="mr-5"
              alt="upload file"
            />
            {!fileToUpload?.isUploaded && (
              <>

                <button
                  type="button"
                  onClick={uploadFile}
                  className="mr-5 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-0 px-2 border border-blue-500 hover:border-transparent rounded"
                >
                  Upload
                </button>
              </>
            )}
            <button
              type="button"
              onClick={() => handleCancel()}
              className="bg-transparent hover:bg-red-500 text-red-500 text-small-text mt-1  font-semibold hover:text-white py-0 px-2 border border-red-500 hover:border-transparent rounded"
            >
              {fileToUpload?.isUploaded ? "Remove" : "Cancel"}
            </button>
          </div>
        ) : (
          <div className="relative">
            <input
              type="file"
              value={""}
              accept="image/*"
              className="form-input disabled:bg-gray-200"
              onChange={handleFileChange}
              disabled={isReadOnly}
            />
            {helperText && <InfoButton text={helperText} />}
          </div>
        )}

        <ErrorMessage
          message={validationErrors.find((el) => el?.id === id)?.message}
        />
      </div>
    </>
  );
};

export default Upload;
