import React from "react";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <div className="h-screen bg-gray-100 w-screen flex items-center justify-center text-xxl text-neutral-800">
      <div className="text-center">
        <h1 className="text-4xl font-bold mb-4">404</h1>
        <p className="text-gray-700 text-lg">Oops! The page you're looking for does not exist.</p>
        <Link to="/" className="text-blue-500 hover:text-blue-700 mt-1">Go to homepage</Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
