import React from "react";

const ContactInformation = ({
  contactInfo,
  setContactInfo,
  setErrorsContactInfo,
  errorsContactInfo,
  validateFieldContactInfo,
}) => {
  const handleChange = (name, value) => {
    // Clear the error for the field
    // setErrorsContactInfo((prevErrors) => ({
    //   ...prevErrors,
    //   [name]: "",
    // }));
    setContactInfo((prevData) => ({ ...prevData, [name]: value }));

    validateFieldContactInfo(name, value);
  };

  return (
    <>
      <div className="w-full bg-white shadow-lg rounded-md border border-neutral-200 mb-6 p-6 z-10">
        <div className="block uppercase tracking-wide text-gray-500 text-lg font-bold mb-6">
          Contact Information
        </div>

        <div className="grid grid-cols-12 gap-6">
          <CommonInput
            label={"First Name"}
            name={"firstName"}
            handleChange={handleChange}
            errorsContactInfo={errorsContactInfo}
          />
          <CommonInput
            label={"Last Name"}
            name={"lastName"}
            handleChange={handleChange}
            errorsContactInfo={errorsContactInfo}
          />
          <CommonInput
            label={"Phone"}
            name={"phone"}
            handleChange={handleChange}
            errorsContactInfo={errorsContactInfo}
          />
          <CommonInput
            label={"Email"}
            name={"email"}
            handleChange={handleChange}
            errorsContactInfo={errorsContactInfo}
          />
        </div>
      </div>
    </>
  );
};

const CommonInput = ({ label, name, handleChange, errorsContactInfo }) => {
  return (
    <>
      <div className="col-span-12 lg:col-span-6">
        <label className="w-full block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2">
          {label}
          <span className="text-rose-500 text-xl leading-none">*</span>
        </label>
        <input
          name={name}
          className={`form-input ${
            errorsContactInfo[name] ? "border-red11" : ""
          }`}
          onChange={(e) => {
            handleChange(e.target.name, e.target.value);
          }}
        />
        {errorsContactInfo[name] ? (
          <div className="text-red-500 text-small-text mt-1 ">
            {errorsContactInfo[name]}
          </div>
        ) : (
          <div className="text-red-500 text-small-text mt-1">&nbsp;</div>
        )}
      </div>
    </>
  );
};

export default ContactInformation;
