import React from "react";

const BannerImageSection = ({ bannerImage }) => {
  return (
    <div className="text-center mb-[36px]">
      <img
        src={bannerImage}
        className="inline-block"
        alt="banner"
      />
    </div>
  );
};

export default BannerImageSection;
