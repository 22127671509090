import React from "react";
import Required from "./Required";

const DatePicker = ({
  required,
  label,
  handleChange,
  id,
  defaultValue,
  isReadOnly,
  parentId,
  value,
  formLabelFontSize,
}) => {
  return (
    <div className="w-full  px-[15px] mb-[15px] ">
      <label
        className={`font-bold mb-[5px] ${formLabelFontSize ? formLabelFontSize : "text-normal-text"
          }`}
      >
        {label} {required && <Required />}
      </label>
      <div>
        <input
          disabled={isReadOnly}
          type="date"
          value={value}
          className="form-input disabled:bg-gray-200"
          // defaultValue={defaultValue}
          onChange={(e) => handleChange(id, e.target.value, {}, parentId)}
        />
      </div>
    </div>
  );
};

export default DatePicker;
