import { createSlice } from "@reduxjs/toolkit";

const initialState = { formField: null };

const TempDataSlice = createSlice({
  name: "TempData",
  initialState,
  reducers: {
    setFormField: (state, action) => {
      return { ...state, formField: action.payload };
    },
  },
});

export const { setFormField } = TempDataSlice.actions;
export default TempDataSlice.reducer;
