export const alertMessages = {
    fieldsCreated: "Fields created successfully",
    enterRequiredFields: "Please enter required fields",
    formSaved: "Form saved successfully",
    shippingAddressAdded:"Shipping address added successfully",
    errorOccured: "Some error occurred",
    productAdded: "Product added successfully",
    imageFileOnly: 'Please choose image files only.',
    verifyCaptcha: 'Please verify captcha.'
}

export const validationMessages = {
    required: {
        email: "Please enter email"
    },
    email: 'Please enter a valid email',
    invalidAlphabets: 'Please enter valid alphabets',
    invalidAlphaNumeric: "Please enter AlphaNumeric characters",
    invalidNumbers: "Please enter valid numbers",
    
    
}