import React, { useContext } from "react";
import { FormContext } from "../contexts/FormContext";

export default function Modal({ children }) {
  const { modalState, setmodalState } = useContext(FormContext);

  return (
    <div
      id="editcustomerModal"
      className={`overflow-y-auto overflow-x-hidden fixed right-0 left-0 top-4 z-50 justify-center items-center h-modal md:h-full md:inset-0 ${modalState?.isVisible ? "" : "hidden"
        }`}
    >
      <div className="w-full h-full bg-gray-500 bg-opacity-50 flex items-center justify-center">
        <div className="relative px-4 w-full max-w-4xl h-full md:h-auto">
          <div className="relative bg-white rounded-lg shadow max-h-screen overflow-y-auto">
            <div className="flex justify-between items-start p-5 rounded-t border-b sticky top-0 left-0 bg-white">
              <h3 className="text-xl font-semibold text-gray-900 lg:text-2xl">
                {modalState?.title}
              </h3>
              {modalState?.isClose ? (
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                  onClick={() => {
                    setmodalState((prev) => ({ ...prev, isVisible: false }));
                  }}
                >
                  <span className="material-icons-outlined leading-10">close</span>
                </button>
              ) : (
                <></>
              )}
            </div>
            <div className="p-6">
              <div>{children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
